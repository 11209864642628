import React, {useEffect} from 'react';
import {Login} from "./Login";
import {Register} from "./Register";
import {Confirm} from "./Confirm";
import {useSelector} from "react-redux";
import {useStateContext} from "../../Helpers/auth/AuthProvider";
import {Navigate} from "react-router-dom";


export const Sign = () => {
    const action = useSelector(state => state.sign.page) || null;
    const {token} = useStateContext();

    useEffect(() => {
        if (token) {
            return <Navigate to="/account/history" />
        }
    }, []);

    function renderSwitch(action) {
        switch (action) {
            case 'login': return <Login/>
            case 'register': return <Register/>
            case 'confirm': {
                if (localStorage.getItem('user')) {
                    return <Confirm/>
                }
                return <Login/>
            }
            default: return <Login/>
        }
    }

    return (
        <div className="container">
            {renderSwitch(action)}
        </div>
    );
};