import React from "react";
import {Link, useParams} from "react-router-dom";
import { useClipboard } from 'use-clipboard-copy';
import CopySvg from 'images/copy.svg';
import DangerSvg from 'images/danger.svg';
import axiosClient from "../../Helpers/http/axiosClient";

const OrderBody = ({ data }) => {

    const param = useParams();
    const [status, setStatus] = React.useState("");
    const orderStatusUpdateTime = 20;
    const clipboard = useClipboard();

    const orderStatus = {
        0: "Отменен",
        1: "В ожидании оплаты",
        2: "Оплачено",
        3: "Выполнен"
    };

    const timer = (time) => {
        return time;
    }


    React.useEffect(() => {
        setStatus(orderStatus[data.status]);
    }, [data.status]);

    const cancelOrder = async () => {
        const res = await axiosClient(
            process.env.REACT_APP_API_URL + '/cancelOrder/' + param.id
        );
        setStatus(res.data ? orderStatus[res.data] : orderStatus[1])
    }

    const orderTimeIncrease = () => {};

    const updateOrderStatus = async () => {
        const res = await axiosClient.get(
            process.env.REACT_APP_API_URL + '/order/'+ param.id + '/status'
        );
        console.log(res.data);
        setStatus(res.data ? orderStatus[res.data] : orderStatus[0]);
    }

    // console.log(data);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (status !== orderStatus[0] || status !== orderStatus[3]) {
                updateOrderStatus()
                    .then(() => {})
            }
        }, orderStatusUpdateTime * 1000);
        return () => clearInterval(interval);
    });

    return (

        <div className="ob_div">
            <div className="ob_d_nonce">

                {
                    data.type_from === 'crypto' && (
                        <div className="ob_d_n_levelnext">
                            <p>Переведите <span onClick={() => {
                                clipboard.copy(data.amount_from)
                            }}><img src={CopySvg} alt="copy"/> {data.amount_from} {data.title_from}</span> на
                                адрес <span onClick={() => {
                                    clipboard.copy(data.incoming_asset)
                                }}><img src={CopySvg} alt="copy"/> {data.incoming_asset}</span>
                            </p>
                            <div>
                                <img src={DangerSvg} alt="danger"/>
                                Платформы Garantex.org , Capitalist.net , Commex.com , Bitpapa.com , NetEx24.net находятся под санкциями.<br/>
                                Монеты с данных платформ могут быть заморожены до принятия решения правоохранительными органами. Будьте внимательны!
                            </div>
                        </div>
                    )
                }

                {
                    data.type_from === 'bank' && (
                        <div className="ob_d_n_levelnext">
                            <p>
                            Переведите <span onClick={() => {clipboard.copy(data.amount_from)}}>
                                    <img src={CopySvg} alt="copy"/> {data.amount_from} RUB
                                </span> в <span className="bank">Промсвязьбанк</span> по номеру <span
                                    onClick={() => {
                                        clipboard.copy(data.incoming_asset)
                                    }}>
                                    <img src={CopySvg} alt="copy"/> +{data.incoming_asset}
                                </span>
                            </p>
                            <div>
                                <img src={DangerSvg} alt="danger"/>
                                Поле комментария оставьте пустым, иначе платеж может быть не принят.
                            </div>
                        </div>
                    )
                }

            </div>

            <div className="ob_d_info">

                <div className="ob_d_i">
                    <div className="ob_d_i_left"><p>Отдаете:</p></div>
                    <div className="ob_d_i_right">
                        <p>
                            {data.amount_from} {data.title_from}
                            {/*<img src={`./../images/logo_${data.cur_from}.svg`} alt={data.title_from}/>*/}
                        </p>

                    </div>
                </div>

                <div className="ob_d_i">
                    <div className="ob_d_i_left"><p>Получаете:</p></div>
                    <div className="ob_d_i_right"><p>{data.amount_to} {data.title_to}</p></div>
                </div>

                <div className="ob_d_i">
                    <div className="ob_d_i_left"><p>Курс обмена:</p></div>
                    <div className="ob_d_i_right">
                        <div
                            className="ob_d_i_r_left">{data.rateFrom} {data.title_from} = {data.rateTo} {data.title_to}</div>
                        <div className="ob_d_i_r_right">18:07</div>
                    </div>
                </div>

                <div className="ob_d_i">
                    <div className="ob_d_i_left"><p>Email:</p></div>
                    <div className="ob_d_i_right"><p>{data.email}</p>
                    </div>
                </div>

                <div className="ob_d_i">
                    <div className="ob_d_i_left"><p>Статус заказа:</p></div>
                    <div className="ob_d_i_right"><p>{status}</p>
                    </div>
                </div>


                {
                    status === orderStatus[1] && (
                        <div className="ob_d_i">
                            <div className="ob_d_i_left"><p>Время действия заказа:</p></div>
                            <div className="ob_d_i_right">
                                <div className="ob_d_i_r_left">{timer(30)} мин</div>
                                <div className="ob_d_i_r_right"><Link to="" onClick={orderTimeIncrease()}>Продлить</Link>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="ob_d_footer">
                    <div className="ob_d_f_left">
                        {
                            status === orderStatus[1] && (

                                <button className="red_btn" onClick={() => cancelOrder()}><span>Отменить</span></button>

                            )
                        }
                    </div>
                    <div className="ob_d_f_right">
                        <button className="empty_btn" type="button"><span>Сделать шаблоном</span></button>
                    </div>
                    </div>
                </div>

            </div>
            );
            };

export default OrderBody;