import React, {useEffect, useState} from "react";
import {Routes,Route, useSearchParams} from "react-router-dom";

// CSS
import './css/reset.css';
import './css/base.css';
import './css/App.css';
import './css/media.css';
import './css/fonts/PT_Root/stylesheet.css';
import 'react-loading-skeleton/dist/skeleton.css';

// Components jsx
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Main from "./Components/PagesExternal/MainPage/Main";
import NotFound from "./Components/PagesExternal/NotFound";
import Order from "./Components/PagesExternal/Order/Order";
import Bonus from "./Components/PagesExternal/Bonus";
import Partner from "./Components/PagesExternal/Partner";
import Rules from "./Components/PagesExternal/Rules";
import {Sign} from "./Components/PagesExternal/SignPage/Sign";
import UserFavourites from "./Components/User/PagesInternal/UserFavourites";
import UserHistory from "./Components/User/PagesInternal/UserHistory";
import UserVerifyCard from "./Components/User/PagesInternal/UserVerifyCard";
import UserPartner from "./Components/User/PagesInternal/UserPartner";

// Helpers
import axiosClient from "./Components/Helpers/http/axiosClient";
import SearchUserInfo from "./Components/Helpers/SearchUserInfo";
import getCookie from "./Components/customHooks/getCookie";
import setCookie from "./Components/customHooks/setCookie";
import {Provider} from "react-redux";
import {store} from "./redux";
import PrivateRoute from "./Components/User/PrivateRoute/PrivateRoute";
import UserLogout from "./Components/User/PagesInternal/UserLogout";


function App() {

    const [userIp, setUserIp] = useState('') || null;
    const [searchParams, setSearchParams] = useSearchParams();
    const referrer = searchParams.get('ref') || null;

    const getIp = async () => {
        const response = await SearchUserInfo();
        setUserIp(response.ipAddress);
    }
    if (!localStorage.getItem('userIp')) {
        getIp().then(r => {});
        localStorage.setItem('userIp', userIp);
    }

    if (referrer) {
        localStorage.setItem('ref', referrer);
    }

  return (
    <div className="App">
        <Provider store={store}>
            <Header />
            <main>
                <div className="container pd-l12 pd-r12">
                    <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/order/:id" element={<Order />} />
                        <Route path="/bonus/" element={<Bonus />} />
                        <Route path="/partner/" element={<Partner />} />
                        <Route path="/rules/" element={<Rules />} />
                        <Route path="/sign/" element={<Sign />}/>

                        <Route element={<PrivateRoute />} >
                            <Route path="/account/history/" element={<UserHistory />} />
                            <Route path="/account/favourites/" element={<UserFavourites />} />
                            <Route path="/account/cards/" element={<UserVerifyCard />} />
                            <Route path="/account/partner/" element={<UserPartner />} />
                            <Route path="/account/logout/" element={<UserLogout />} />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </main>
            <Footer />
        </Provider>
    </div>
  );
}

export default App;
