import React, {useState} from "react";



export const TimerDownLink = ({
      hours = 0,
      minutes = 0,
      seconds = 0,
      action = null
}) =>
{
    const [paused, setPaused] = useState(false);
    const [over, setOver] = useState(false);
    const [[h, m, s], setTime] = useState([hours, minutes, seconds]);

    const tick = () => {
        if (paused || over) return;

        if (h === 0 && m === 0 && s === 0) {
            setOver(true);
        } else if (m === 0 && s === 0) {
            setTime([h - 1, 59, 59]);
        } else if (s === 0) {
            setTime([h, m - 1, 59]);
        } else {
            setTime([h, m, s - 1]);
        }
    };

    const reset = () => {
        setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
        setPaused(false);
        setOver(false);
    };

    React.useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    const actionAndReset = async () => {
        if (action) {
            await action();
        }
        reset();
    };

    return (
        <div>

                {
                    over ? (
                            <a href="#" onClick={actionAndReset}>отправить</a>
                    ) : (
                        <p>
                            {`${h.toString().padStart(2, '0')}:${m
                            .toString()
                            .padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
                        </p>
                    )
                }

                {/*<p>{`${h.toString().padStart(2, '0')}:${m*/}
                {/*    .toString()*/}
                {/*    .padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</p>*/}
                {/*<div>{over ? "Time's up!" : ''}</div>*/}
                {/*<button onClick={() => setPaused(!paused)}>*/}
                {/*    {paused ? 'Resume' : 'Pause'}*/}
                {/*</button>*/}
                {/*<button onClick={() => reset()}>Restart</button>*/}
        </div>
    );
};