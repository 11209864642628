import {useStateContext} from "../../Helpers/auth/AuthProvider";
import axiosClient from "../../Helpers/http/axiosClient";
import {Navigate} from "react-router-dom";

export default function UserLogout() {

    const {token, setToken} = useStateContext();
    const logout = async () => {
        await axiosClient.get(process.env.REACT_APP_API_LOGOUT_URL);
        setToken(null);
    }
    if (token) {
        logout().catch(r => {});
    }

    return (
        <Navigate to="/" />
    );
}
