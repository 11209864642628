import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {pageConfirm, pageRegister} from "../../../redux/slices/signSlice";
import {Field, Form, Formik} from "formik";
import {signValidation} from "../../Helpers/validation/signValidation";
import { useNavigate } from 'react-router-dom';
import axiosClient from "../../Helpers/http/axiosClient";
import {useStateContext} from "../../Helpers/auth/AuthProvider";


export const Login = () => {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {setToken, setTokenExp} = useStateContext();


    async function sendForm(values) {
        let fData = new FormData();
        fData.append('email', values.email);
        fData.append('password', values.password);

        const res = await axiosClient.post(
            process.env.REACT_APP_API_LOGIN_URL,
            fData,
        );

        if (res.data && res.status === 200) {
            if ('email_verified' in res.data.options && res.data.options.email_verified === false) {
                localStorage.setItem('user',res.data.user);
                localStorage.setItem('REG_TOKEN',res.data.access_token);
                dispatch(pageConfirm());
            } else {
                localStorage.setItem('user',res.data.user);
                setToken(res.data.access_token);
                setTokenExp(res.data.expires_in);
                navigate("/account/history");
            }
        }
        if (res.status !== 200 && res.data) {
            setError(res.data.message);
        }
    }

    React.useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError('');
            }, 5000);
        }
    });

    return (
        <>
            <div className="page_head">
                <div className="log_head_title">
                    <h2>Вход</h2>
                </div>
            </div>
            <div className="page_body">

                    <Formik
                        validationSchema={signValidation}
                        initialValues={{email: '', password: ''}}
                        onSubmit={values => {sendForm(values).then(r => {})}}
                    >
                        {
                            ({ errors, touched }) => (

                                <Form>
                                    <div className="log_body_signin">

                                        {
                                            error && (
                                                <span className="red">{error}</span>
                                            )
                                        }

                                        <Field
                                            type="text"
                                            autoComplete = "off"
                                            className="log_body_sin_input"
                                            placeholder="E-mail"
                                            name = "email"
                                        />
                                            {
                                                errors.email && touched.email && (
                                                    <span className="red">{errors.email}</span>
                                                )
                                            }

                                        <Field
                                            type="password"
                                            autoComplete = "off"
                                            className="log_body_sin_input"
                                            placeholder="Пароль"
                                            name = "password"
                                        />
                                        {
                                            errors.password && touched.password && (
                                                <span className="red">{errors.password}</span>
                                            )
                                        }

                                        <button
                                            type="submit"
                                            className="yellow_btn log_body_sin_btn"
                                        ><span>Войти</span></button>
                                    </div>
                                </Form>

                            )

                        }

                    </Formik>

                <div className="log_body_signup"><a href='#' onClick={() => dispatch(pageRegister()) }>Зарегистрироваться</a></div>
            </div>
        </>
    )
}
