import {useStateContext} from "../../Helpers/auth/AuthProvider";
import {Navigate, Outlet} from "react-router-dom";


export default function PrivateRoute() {
    const {token} = useStateContext();

    return (
        token ? <Outlet /> : <Navigate to="/sign/" />
    );
}