import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./Components/Helpers/auth/AuthProvider";
import App from "./App";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
      <BrowserRouter
          future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      >
          <App />
      </BrowserRouter>
  </AuthProvider>
);


