import React, {useEffect} from 'react';
import OrderBody from "./OrderBody";
import {useParams} from "react-router-dom";
import FAQ from "../MainPage/FAQ";
import axiosClient from "../../Helpers/http/axiosClient";


const Order = () => {

    const dataParam = useParams();
    const [orderData, setOrderData] = React.useState({});

    const getOrderData = async (id) => {
        const res = await axiosClient.get(
            process.env.REACT_APP_API_URL + '/order/' + id
        );
        setOrderData(res.data ? res.data : {});
    }

    // Загрузка компонента при первом рендере компонента
    useEffect(() => {
        // Загрузка данных заказа
        if (Object.keys(orderData).length === 0) {
            getOrderData(dataParam.id).then(r => {});
        }
        document.title = 'Заказ на обмен №' + dataParam.id;
    },[]);

    return (
        <div className="container">

            <div className="page_head">
                <div className="log_head_title">
                    <h2>Оплата заказа № {dataParam.id}</h2>
                </div>
            </div>
            <OrderBody data={orderData}/>
            <FAQ />
        </div>
    );
};

export default Order;