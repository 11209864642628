
import getCookie from "../../customHooks/getCookie";
import axios from "axios";


const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type":"application/json",
        "Accept":"application/json",
        "X-Requested-With":"XMLHttpRequest",
    },
    withCredentials: true, // Enable cookies
});

axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('REG_TOKEN')?
        localStorage.getItem('REG_TOKEN') : localStorage.getItem('ACCESS_TOKEN');
    if (token) {
        config.headers.Authorization = "Bearer " + token;
    }
    config.headers.set("X-XSRF-TOKEN", getCookie('XSRF-TOKEN'));
    return config;
});

axiosClient.interceptors.response.use((response) => {
    // console.log(response);
    return response;
}, (error) => {
    const {response} = error;
    // console.log(response);
    return response;
});


export default axiosClient;