import {createSlice} from "@reduxjs/toolkit";
import {CONFIRM, LOGIN, REGISTER} from "../types";

const signSlice = createSlice({
    name: 'signSlice',
    initialState: { page: LOGIN},
    reducers: {
        pageLogin(state) {
            state.page = LOGIN
        },
        pageRegister(state) {
            state.page = REGISTER
        },
        pageConfirm(state) {
            state.page = CONFIRM;
        }
    }
});

export const { pageLogin, pageRegister, pageConfirm } = signSlice.actions;
export default signSlice.reducer;