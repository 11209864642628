import React, {useEffect} from 'react';
import Input from "./UIElements/Input";
import { Navigate , useNavigate } from 'react-router-dom';
import {signValidation} from "../../Helpers/validation/signValidation";
import {Field, Form, Formik} from "formik";
import {recepientValidation} from "../../Helpers/validation/recepientValidation";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import axiosClient from "../../Helpers/http/axiosClient";


const MainSectionExch = () => {

    const navigate = useNavigate();

    // Данные для обмена
    const [data, setData] = React.useState({
        currencies: {},
        exchange_pairs: {}
    });

    // Установка валют
    const [fromCurrency,setFromCurrency] = React.useState('');
    const [toCurrency,setToCurrency] = React.useState('');

    // Курс обмена
    const [fromPrice,setFromPrice] = React.useState(0);
    const [toPrice,setToPrice] = React.useState(0);

    // Установка допустимых валют для обмена
    const [fromCurs,setFromCurs] = React.useState([]);
    const [toCurs,setToCurs] = React.useState([]);

    // Названия валют
    const [getTitles, setGetTitles] = React.useState([]);

    // Курс обмена
    const [rateExch,setRateExch] = React.useState('');

    // Мин. и макс. сумма
    const [minFromSum,setMinFromSum] = React.useState(null);
    const [maxFromSum,setMaxFromSum] = React.useState(null);
    const [maxToSum,setMaxToSum] = React.useState(null);

    // Проверка на фиат для округления
    const [isFiat, setIsFiat] = React.useState(false);
    const [curInfo, setCurInfo] = React.useState();
    const time = 30;

    // Formik errors
    const [error, setError] = React.useState('');
    const [errorCode, setErrorCode] = React.useState('');

    const getBackendRates = async () => {
        const response = await axiosClient.get('/rates');
        setData(response.data);
    }

    async function sendForm(values) {

        let fData = new FormData();

        fData.append('email', values.email);
        fData.append('recipientAccount', values.account);
        fData.append('curFrom', values.curFrom);
        fData.append('curTo', values.curTo);
        fData.append('amountFrom', values.amountFrom);
        fData.append('amountTo', values.amountTo);
        fData.append('rateFrom', values.rateFrom);
        fData.append('rateTo', values.rateTo);

        // await sendForm(fData)
        //     .then(res => {
        //         localStorage.setItem("user_email", values.email);
        //         if (res) {
        //             navigate("/order/" + res);
        //         }
        //         if (res.error) setErrorCode(res.error);
        //     })
        //     .catch(error => console.log(error))
    }

    const dataCurs = data?.currencies;
    const dataPairs = data?.exchange_pairs;

    const curInfoPrepare = () => {
        let curInfoPrepare = {};
        for (let key1 in dataCurs) {
            if (dataCurs.hasOwnProperty(key1)) {
                curInfoPrepare[key1] = {};
                curInfoPrepare[key1].title = dataCurs[key1].title;
                curInfoPrepare[key1].type = dataCurs[key1].type;
                curInfoPrepare[key1].rate_to_usd = dataCurs[key1].rate_to_usd;
                curInfoPrepare[key1].balance = dataCurs[key1].balance;
                curInfoPrepare[key1].inc_min_amount = dataCurs[key1].inc_min_amount;
                curInfoPrepare[key1].inc_max_amount = dataCurs[key1].inc_max_amount;
                curInfoPrepare[key1].outc_min_amount = dataCurs[key1].outc_min_amount;
                curInfoPrepare[key1].outc_max_amount = dataCurs[key1].outc_max_amount;
            }
        }
        return curInfoPrepare;
    }

    const curNames = () => {
        let titles = [];
        for (const [coin,values] of Object.entries(curInfoPrepare())) {
            titles[coin] = values.title;
        }
        return titles;
    }

    const getRateExch = () => {

        if (!fromCurrency || !toCurrency) return;

        for (const [keyRE, valueRE] of Object.entries(dataPairs)) {
            if (keyRE !== fromCurrency) continue;
            for (const [keyTRE, valueTRE] of Object.entries(valueRE)) {
                if (valueTRE.cur_to && valueTRE.profit && valueTRE.cur_to === toCurrency) {
                    setRateExch(valueTRE.profit);
                    break;
                }
            }
        }
    }

    const defaultExchange = () => {
        // Выбираем первую пару валют для обмена
        if (!fromCurrency && !toCurrency && dataPairs) {

            for (const [keyFE, valueFE] of Object.entries(dataPairs)) {
                for (const [keyTE, valueTE] of Object.entries(valueFE)) {
                    if (valueTE.cur_to && valueTE.profit) {
                        setFromCurrency(keyFE);
                        setToCurrency(valueTE.cur_to);
                        break;
                    }
                }
            }
        }
    }

    // Доступные валюты для обмена
    const availableCurs = () => {

        if (!dataPairs) return;

        let availableCurs = {
            from: [],
            to: []
        };
        for (const [keyF,value] of Object.entries(dataPairs)) {

            if (keyF === fromCurrency) continue;

            (availableCurs.from).push(keyF);

            for (const [keyT,valueT] of Object.entries(value)) {

                if (valueT.cur_to === toCurrency || (availableCurs.to).includes(valueT.cur_to)) continue;
                (availableCurs.to).push(valueT.cur_to);
            }

        }

        return availableCurs;
    }

    const changeCurFrom = () => {
        for (const [k,arr] of Object.entries(dataPairs)) {

            if (k !== fromCurrency) continue;

            for (const [t,arrT] of Object.entries(arr)) {

                if (arrT.cur_to) {
                    setToCurrency(arrT.cur_to);
                    break;
                }
            }
            break;
        }
    }

    const changeCurTo = () => {
        if (!fromCurrency || !toCurrency) return;
        let toCurArr = [];
        for (const [o,l] of Object.entries(dataPairs)) {
            if (o !== fromCurrency) continue;
            for (const [n,m] of Object.entries(l)) {
                if (m.cur_to !== toCurrency) {
                    toCurArr.push(m.cur_to);
                }
            }
        }
        return toCurArr;
    }

    const rateRow = () => {
        if (!fromCurrency ||!toCurrency) return;
        if (curInfo[fromCurrency].rate_to_usd === curInfo[toCurrency].rate_to_usd) {
            setFromPrice(Number(curInfo[fromCurrency].rate_to_usd) * Number(rateExch));
            setToPrice(Number(curInfo[toCurrency].rate_to_usd));
        }
    }

    const setMinMax = () => {

        if (!fromCurrency || !toCurrency || !curInfoPrepare()) return;

        setMinFromSum(curInfoPrepare()[fromCurrency].inc_min_amount);
        setMaxFromSum(curInfoPrepare()[fromCurrency].inc_max_amount);
        setMaxToSum(curInfoPrepare()[toCurrency].outc_max_amount);

    }

    useEffect(() => {
        if (
            Object.keys(data.currencies).length === 0 &&
            Object.keys(data.exchange_pairs).length === 0
        ) {
            try {
                getBackendRates().then(r => {});
            } catch (e) {
                console.warn(e.message());
            }
        }

        if (!getTitles) setGetTitles(curNames());

        if (!fromCurrency && !toCurrency) {
            defaultExchange();
        }
        rateRow();
        getRateExch();
        if (!curInfo) setCurInfo(curInfoPrepare());
        setMinMax();
    });

    // Обновление курсов каждые "time" минут
    useEffect(() => {
        const interval = setInterval(() => {
            getBackendRates()
                .then(() => {})
                .catch((err) => console.warn(err))
        }, time * 1000);
        return () => clearInterval(interval);
    });

    useEffect(() => {
        if (!fromCurrency && !toCurrency) {
            defaultExchange();
        }
        setCurInfo(curInfoPrepare());
        setGetTitles(curNames());
        setMinMax();
        getRateExch();
    },[data]);

//console.log(curInfoPrepare());

    useEffect(() => {
        if (fromCurrency) changeCurFrom();
        setFromCurs(availableCurs()?.from);
    },[fromCurrency]);

    useEffect(() => {
        setToCurs(changeCurTo());
    },[toCurrency]);

    const chooseFromCurrency = (e) => {
        setFromCurrency(e.target.id);

        //setCookie('fc',e.target.id);
        // let url = '/exchanger/' + e.target.id + '-' + toCurrency + '/';
        // setPostUrl(url);
        // getPost(url).then(r => {});
    }

    const chooseToCurrency = (e) => {
        setToCurrency(e.target.id);
    }

    return (
        <section className="index_exch">
            <div className="i_ex_heading">
                <h1>Автоматический обмен</h1>
            </div>

            <div className="tmp" id="tmp-exchanger">

                <div className="i_ex_rate">
                    {
                        fromCurrency && toCurrency ? (
                            <p>
                                <img src={fromCurrency ? `images/logo_${fromCurrency}.svg` : ''}
                                     alt={fromCurrency}/> {fromPrice} = {Number(toPrice)} <img
                                src={toCurrency ? `images/logo_${toCurrency}.svg` : ''} alt={toCurrency}/>
                            </p>
                        ) : (<p><Skeleton width={100}/></p>)
                    }
                    {/*<span className="timer">{rateTime}</span>*/}
                </div>

                <Formik
                    validationSchema={recepientValidation}
                    initialValues={{
                        email: '',
                        account: '',
                    }}
                    onSubmit={values => {
                        values.curFrom = fromCurrency;
                        values.curTo = toCurrency;
                        values.amountFrom = document.getElementById('fs').value;
                        values.amountTo = document.getElementById('ts').value;
                        values.rateFrom = fromPrice;
                        values.rateTo = toPrice;
                        sendForm(values).then(r => {
                        })
                    }}
                >
                    {
                        ({errors, touched}) => (

                            <Form>
                                <div className="i_ex_fields">
                                    <div className="i_ex_f_elems">

                                        <Input
                                            selectFrom={fromCurs}
                                            selectTo={toCurs}
                                            currencyFrom={fromCurrency}
                                            currencyTo={toCurrency}
                                            titles={getTitles}
                                            factorFrom={2}
                                            factorTo={2}
                                            chooseFrom={chooseFromCurrency}
                                            chooseTo={chooseToCurrency}
                                            sumFromMin={minFromSum}
                                            sumFromMax={maxFromSum}
                                            sumToMax={maxToSum}
                                            rate={rateExch}
                                            isFiat={isFiat}
                                        />

                                    </div>
                                </div>

                                {
                                    error && (
                                        <span className="red">{error}</span>
                                    )
                                }

                                <div className="i_ex_recdata">
                                    {
                                        errors.email && touched.email && (
                                            <span className="red">{errors.email}</span>
                                        )
                                    }

                                    <Field
                                        autoComplete="off"
                                        placeholder="E-mail"
                                        name="email"
                                    />

                                    {
                                        errors.account && touched.account && (
                                            <span className="red">{errors.account}</span>
                                        )
                                    }

                                    <Field
                                        autoComplete="off"
                                        placeholder={`Счет ${getTitles[toCurrency]}`}
                                        name="account"
                                    />

                                </div>

                                <div className="i_ex_btn">
                                    <button className="yellow_btn" type="submit">Обменять</button>
                                </div>
                            </Form>

                        )

                    }

                </Formik>

            </div>
        </section>
    );
};

export default MainSectionExch;