import React from "react";
import {pageRegister} from "../../../redux/slices/signSlice";
import {useDispatch} from "react-redux";
import { Formik, Form, Field } from "formik";
import { useNavigate } from 'react-router-dom';
import {confirmValidation} from "../../Helpers/validation/confirmValidation";
import {TimerDownLink} from "../../additional/TimerDownLink";
import axiosClient from "../../Helpers/http/axiosClient";
import {useStateContext} from "../../Helpers/auth/AuthProvider";


export const Confirm = () => {
    const {setToken, setTokenExp} = useStateContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorCode, setErrorCode] = React.useState('');

    async function sendForm(values) {
        let fData = new FormData();
        fData.append('code', values.code);
        fData.append('email', localStorage.getItem('user'));

        const res = await axiosClient.post(
            process.env.REACT_APP_API_CONFIRM_EMAIL_URL,
            fData,
        );

        if (res.data) {
            if (res.status === 401) {
                localStorage.removeItem('REG_TOKEN');
                dispatch(pageRegister());
            }
            if (res.status === 400) {
                setErrorCode("Неверный код подтверждения");
            }
            if (res.status === 200 && res.data.user && res.data.access_token) {
                setToken(res.data.access_token);
                setTokenExp(res.data.expires_in);
                navigate("/account/cards");
            }
        } else {
            setErrorCode("Аккаунт не может быть создан. Обратитесь в техподдержку в виджете.");
        }
    }

    const sendEmailVerify = async () => {
        await axiosClient.get(process.env.REACT_APP_API_REPEAT_EMAIL_VERIFY_URL);
    }

    const registerChangeEmail = () => {
        localStorage.removeItem('user');
        setToken(null);
        setTokenExp(null);
        dispatch(pageRegister());
    }

    React.useEffect(() => {
        if (errorCode) {
            setTimeout(() => {
                setErrorCode('');
            }, 5000);
        }
    });

    return (
        <>
            <div className="page_head">
                <div className="log_head_title">
                    <h2>Авторизация</h2>
                </div>
            </div>

            <div className="page_body">

                <Formik
                    validationSchema={confirmValidation}
                    initialValues={{code: ''}}
                    onSubmit={values => {
                        sendForm(values)
                    }}
                >
                    {({errors, touched}) => (

                        <Form>
                            <div className="log_body_sign_code">
                                <div className="log_b_s_c_label">
                                    <p>Необходимо верифицировать ваш Email.</p>
                                    {
                                        errorCode ? (<span className="red">{errorCode}</span>)
                                            : (<p>Код авторизации отправлен на {localStorage.getItem('user')}</p>)
                                    }
                                    <a href="#" onClick={registerChangeEmail}>Изменить Email</a>
                                    <div className="log_b_s_c_action">
                                        <Field
                                            className="log_body_sin_input"
                                            placeholder="Код"
                                            name="code"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />

                                        <button
                                            type="submit"
                                            className="yellow_btn log_body_sin_btn"
                                        ><span>Войти</span></button>
                                    </div>
                                    {errors.code && touched.code && (
                                        <span className="red">{errors.code}</span>
                                    )}


                                    <p className="opacity50">
                                        Отправить письмо повторно:
                                    </p>
                                    <TimerDownLink
                                        minutes={1}
                                        action={() => sendEmailVerify()}
                                    />
                                </div>
                            </div>
                        </Form>

                    )
                    }

                </Formik>

            </div>
        </>
    )
}