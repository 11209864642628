import React from 'react';

const Rules = () => {
    return (
        <div className="container">
            <div className="page_head">
                <div className="log_head_title">
                    <h3>Условия обмена</h3>
                </div>
            </div>
            <div className="page_body">
                <h5>Правила сервиса Coinschest.io</h5>
                <ul>
                    <li><h6>1. Назначение и область применения Правил сервиса Coinschest.io</h6></li>

                    <li><span>1.1. Настоящие Правила сервиса Coinschest.io (далее по тексту – Правила) устанавливают требования и содержат описание:</span>
                    </li>

                    <li>
                        <span>1.1.1. Порядка предоставления услуги мультивалютного обменного сервиса Coinschest.io.</span>
                    </li>
                    <li><span>1.1.2. Публичной оферты Пользователям услуг сервиса Coinschest.io.</span></li>
                    <li><span>1.1.3. Разграничения ответственности за использование и предоставление услуг сервисом Coinschest.io.</span>
                    </li>
                    <li>
                        <span>1.1.4. Мер по минимизации риска отмывания денежных средств и финансирования терроризма.</span>
                    </li>

                    <li><span>1.2. Coinschest.io или Сервис – система, предоставляющая Пользователям возможность обмена криптовалюты на электронные деньги и (или) национальную валюту, а также обмен электронных денег и (или) национальной валюты на криптовалюту, расположенная и функционирующая на сайте в сети Интернет по адресу “https://coinschest.io/”.</span>
                    </li>

                    <li><span>1.3. Местом оказания услуги Сервиса Пользователю является место размещения сервера, на котором происходит учет и обработка данных и операций Пользователей. Сервер размещается на территории государства Германия. В соответствии с текущим законодательным регулированием, в государстве Германия деятельность по гражданскому обороту криптовалюты нормативно не запрещена.</span>
                    </li>

                    <li><span>1.4. Перед тем, как воспользоваться услугами сервиса Coinschest.io, Пользователь обязан ознакомиться в полном объеме с условиями настоящих Правил, Политикой конфиденциальности Сервиса, опубликованной на сайте Сервиса, и принять их.</span>
                    </li>

                    <li><span>1.5. Использование услуг Сервиса возможно, только если Пользователь принимает все условия настоящих Правил.</span>
                    </li>

                    <li><span>1.6. Действующая версия Правил расположена для публичного доступа на сайте Сервиса. Администрация Сервиса вправе в любое время в одностороннем порядке изменять настоящие Правила. Такие изменения вступают в силу по истечении 3 (трех) календарных дней с момента размещения новой версии Правил на сайте, если иной порядок вступления не предусмотрен специально в новой версии Правил. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к сайту Сервиса и прекратить использование материалов и услуг Сервиса, направив соответствующее письмо на электронную почту, указанную на данном сайте.</span>
                    </li>

                    <li><h6>2. Термины и определения</h6></li>

                    <li><span>2.1. KYC & AML – «Know Your Customer» (знай своего клиента) и «Anti-Money Laundering» (противодействие отмыванию средств).</span>
                    </li>

                    <li><span>2.2. P2P – оверлейная компьютерная сеть, основанная на равноправии участников.</span></li>

                    <li>
                        <span>2.3. Аккаунт – уникальная учетная запись на сайте Сервиса, идентифицирующая Пользователя.</span>
                    </li>

                    <li><span>2.4. Блокчейн – выстроенная по определённым правилам непрерывная последовательная цепочка блоков (связный список), содержащих информацию о проводках между цифровыми ключами (кошельками) пользователей, которую можно передавать от одного компьютера (человека) другому посредством сети интернет.</span>
                    </li>

                    <li><span>2.5. Верификация банковской карты — это проверка принадлежности платежных реквизитов (банковской карты или счета, или электронного кошелька) её владельцу. Условия проверки принадлежности устанавливает Сервис, производится единовременно для каждого нового платежного реквизита (банковской карты или счета, или электронного кошелька) Пользователя или, на усмотрение Сервиса, для каждого платежа.</span>
                    </li>

                    <li><span>2.6. Заказ – выражение намерения Пользователя воспользоваться одной из услуг, предлагаемых Сервисом, путем заполнения электронной формы через сайт Сервиса, на условиях, описанных в настоящих Правилах и указанных в параметрах этого Заказа.</span>
                    </li>

                    <li><span>2.7. Криптовалюта — Bitcoin, USDT, Litecoin, Ethereum и любые иные валюты, основанные на технологии блокчейн.</span>
                    </li>

                    <li><span>2.8. Курс — стоимостное соотношение криптовалюты, электронных денег и фиатной валюты между собой при обмене.</span>
                    </li>

                    <li><span>2.9. Национальная валюта (фиатная валюта) – законные платёжные средства соответствующего государства (рубль – для Российской Федерации, доллар США – для США и т.д.).</span>
                    </li>

                    <li><span>2.10. Партнер – лицо, оказывающее Сервису услуги по привлечению Пользователей, условия оказания которых описаны в настоящих Правилах.</span>
                    </li>

                    <li><span>2.11. Платеж — перевод криптовалюты, электронных денег или фиатной валюты от Пользователя к Пользователю или от Пользователя Сервису, а также в обратном направлении.</span>
                    </li>

                    <li><span>2.12. Платежная система — программно-аппаратный продукт, разработанный третьей стороной и представляющий собой механизм реализации учета денежных обязательств и организации взаиморасчетов между своими Пользователями.</span>
                    </li>

                    <li><span>2.13. Пользователь — дееспособное физическое лицо старше 18 лет, либо юридическое лицо, использующие услуги Сервиса.</span>
                    </li>

                    <li><span>2.14. Сервис — система, предоставляющая Пользователям возможность обмена криптовалюты на электронные деньги и (или) национальную валюту, а также обмен электронных денег и (или) национальной валюты на криптовалюту, расположенная и функционирующая на сайте в сети Интернет по адресу “https://coinschest.io/”.</span>
                    </li>

                    <li><span>2.15. Сообщения – письма, передаваемые посредством электронной почты.</span></li>

                    <li><span>2.16. Средства – криптовалюта, электронные деньги и фиатная валюта, в соответствии с разделом 5 настоящих Правил.</span>
                    </li>

                    <li><span>2.17. Услуги сервиса — оказание содействия в проведении Р2Р-операций между физическими лицами по обмену криптовалют, а также иные услуги, информация о которых размещена на витрине Сервиса.</span>
                    </li>

                    <li><span>2.18. Электронные деньги — денежные средства, находящиеся на счетах Пользователей электронных платежных систем (QIWI, Вебмани и др.).</span>
                    </li>

                    <li><h6>3. Режим работы сервиса</h6></li>

                    <li><span>3.1. Штатный режим работы:</span></li>

                    <li><span>3.1.1. В данном режиме работы заказы Пользователей обрабатываются автоматически в течение времени, отведенного регламентом с 00:01 по 23:59 (UTC+3).</span>
                    </li>
                    <li><span>3.1.2. Поддержка Пользователей ведется операторами на сайте, а также по другим каналам связи способами, указанными на сайте Сервиса.</span>
                    </li>

                    <li><span>3.2. Ночной режим работы:</span></li>

                    <li><span>3.2.1. В данном режиме работы поддержка Пользователей не предусмотрена.</span></li>

                    <li><h6>4. Правила пользования учетной записью на сайте Coinschest.io</h6></li>

                    <li><span>4.1. Сервис предназначен для использования исключительно в личных целях. Регистрируясь на сайте, Пользователь дает свое согласие на передачу Coinschest.io достоверных данных о себе в соответствии с процедурой регистрации на Сайте. Пользователь также соглашается с тем, что не будет использовать какой-либо иной аккаунт, кроме своего, а также не будет пытаться получить несанкционированный доступ к аккаунтам других пользователей либо к инфраструктуре Сервиса.</span>
                    </li>

                    <li><span>4.2. Сервис вправе проводить дополнительные проверки информации Пользователя и запрашивать у Пользователя любую необходимую документацию по любой причине, связанной с использованием им услуг Сервиса и/или в качестве подтверждающего доказательства для любой информации, которую Пользователь предоставляет Сервису.</span>
                    </li>

                    <li><span>4.3. Администрация Сервиса может в любое время и по своему собственному усмотрению отказать Пользователю в возможности открыть аккаунт, заблокировать его или приостановить любую транзакцию до окончания рассмотрения информации, предоставленной Пользователем.</span>
                    </li>

                    <li><h6>5. Порядок оказания услуг Сервисом</h6></li>

                    <li><span>5.1. Заказ услуг Сервиса осуществляется Пользователем через сайт Сервиса.</span></li>

            <li><span>5.2. Воспользовавшись услугами Сервиса, Пользователь подтверждает, что законно владеет, пользуется и распоряжается криптовалютой, электронными деньгами или фиатной валютой, участвующими в соответствующем Платеже.</span></li>

            <li><span>5.3. Сервис не принимает и не отправляет криптовалюту, электронные деньги или фиатную валюту со счетов или на счета третьих лиц, не являющихся Пользователями. Сервис не оказывает Услуги обмена с использованием банковских карт (счетов), не принадлежащих Пользователю. Сервис не вступает в партнерские отношения с торгово-сервисными предприятиями и не является агентом во взаиморасчетах по любым сделкам Пользователя с третьими лицами.</span></li>

            <li><span>5.4. Путем оформления Заказа Пользователь поручает, а Сервис от своего имени и за счет Пользователя, совершает действия по обмену криптовалюты, электронных денег или фиатной валюты с другим Пользователем.</span></li>

            <li><span>5.5. Срок обработки Заказов Сервисом составляет не более 2-х часов после начала работы Сервиса. Средства фиксируются на момент получения их на бирже.</span></li>

            <li><span>5.6. Размер вознаграждения Сервиса за предоставление услуг по обмену отражается в Заказе и подтверждается Пользователем на одной из страниц пользовательского интерфейса.</span></li>

            <li><span>5.7. В течение отведенного регламентом времени (в зависимости от направления обмена, указывается при создании Заказа) с момента получения криптовалюты, электронных денег или фиатной валюты от Пользователя, в размере, указанном в соответствующем Заказе, Сервис обязан перечислить (передать) полученные криптовалюту, электронные деньги или фиатную валюту соответственно на реквизиты и в размере, указанные Пользователем в Заказе.</span></li>

            <li><span>5.8. В случае, когда в процессе обработки Заказа курс изменяется на 1% (один процент) и более, Сервисом производится перерасчет заказа по курсу на момент поступления криптовалюты на счет либо производится возврат Средств с учетом комиссии Платежной системы в эквиваленте USDT на момент фиксации курса по Заказу.</span></li>

            <li><span>5.9. Если в период подтверждения перевода Платежной системой возникла задержка подтверждения транзакции, Сервисом производится перерасчет Заказа по курсу на момент поступления криптовалюты на счет либо производится возврат с учетом комиссии платежной системы в эквиваленте USDT на момент фиксации курса по Заказу.</span></li>

            <li><span>5.10. Сумма возврата для целей, указанных в пунктах 5.8. – 5.9. Настоящих правил, не может рассчитываться в криптовалюте.</span></li>

            <li><span>5.11. Обязанность Сервиса по перечислению (передаче) криптовалюты, электронных денег или фиатной валюты Пользователю считается исполненной в момент списания криптовалюты, электронных денег, или фиатной валюты в соответствующей Платежной системе со счета Сервиса, что регистрируется в истории операций соответствующей Платежной системы.</span></li>

            <li><span>5.12. Если Пользователь оплатил заказ, но в силу обстоятельств желает отказаться от обмена, то возврат Средств происходит за вычетом 10% (десяти процентов) от суммы оплаты, а также за вычетом комиссии соответствующей Платежной системы.</span></li>

            <li><span>5.13. Сервис вправе отменить созданный Пользователем заказ на обмен криптовалюты, электронных денег или фиатной валюты, если оплата по такому заказу не поступила на счет сервиса по истечении 30 (тридцати) минут с момента создания такого заказа.</span></li>

            <li><span>5.14. Если при попытке перевода Сервисом Средств на банковскую карту (счет) Пользователя данная карта (счет) блокируются по причине того, что банковская карта (счет) Пользователя либо сам Пользователь находятся в любого рода «черном списке» или «стоп-листе» соответствующей Платежной системы либо фискального органа государства, резидентом которого является Пользователь, Сервис оставляет за собой право в одностороннем порядке отказаться от предоставления услуг по обмену и произвести возврат Средств Пользователю с удержанием комиссии в размере 20% (двадцати процентов) от текущей суммы обмена.</span></li>

            <li><span>5.15. Возврат Средств в случае, предусмотренном п. 5.13 настоящих Правил, возможен при предоставлении Пользователем документов согласно требованию Сервиса.</span></li>

            <li><span>5.16. В случае обнаружения подозрительной активности в процессе оформления Заказа Пользователем, Сервис, во избежание ущерба, вправе приостанавливать выполнение таких операций до выяснения причин такой активности.</span></li>

            <li><span>5.17. Сервис вправе отказать в выполнении обмена, если передача криптовалюты, электронных денег или фиатной валюты на счет Сервиса была произведена без оформления Заказа при помощи пользовательских интерфейсов на Сайте Сервиса. Криптовалюта, электронные деньги или фиатная валюта, перечисленные на счет Сервиса Пользователем без оформления Заказа при помощи пользовательских интерфейсов на Сайте Сервиса, могут быть возвращены Пользователю по запросу с учетом вычета комиссии платежной системы в соответствии с ограничениями, установленными настоящими Правилами.</span></li>

            <li><span>5.18. Сервис имеет право отказать в предоставлении услуг Пользователю в случае непредоставления Пользователем полных и достаточных данных, необходимых для его идентификации, и заблокировать Средства, полученные от Пользователя до момента сообщения им таких данных.</span></li>

            <li><span>5.19. Сервис оказывает услуги только по обмену криптовалюты на электронные деньги или фиатную валюту, либо наоборот. Сервис не производит валютные операции в отношении национальных денег и не подпадает под национальное и международное законодательство о валютном регулировании и валютном контроле.</span></li>

            <li><span>5.20. Сервис прилагает разумные усилия для обеспечения доступа к услугам и сайту Coinschest.io в соответствии с настоящими Правилами. Тем не менее, Сервис может приостановить использование сайта для технического обслуживания и приложит достаточные усилия, чтобы предварительно уведомить Пользователя об этом. Таким образом, пользователь соглашается с тем, что принимает на себя риски, связанные с тем фактом, что не всегда может пользоваться услугами Сервиса или выполнять срочные транзакции с использованием учетной записи Пользователя.</span></li>

            <li><span>5.21. Сервис имеет право аннулировать обмен при возникновении подозрений о получении Пользователем Средств в результате любой противоправной деятельности в соответствии с законодательством государства размещения Сервиса или государства, резидентом которого является Пользователь.</span></li>

            <li><span>5.22. В ночное время Сервис работает в автоматическом режиме. При обмене криптовалюты в ночное время сумма фиксируется по актуальному курсу на момент зачисления средств на счет Сервиса.</span></li>

            <li><span>5.23. В случае возврата Средства, полученные от Пользователя в ночное время, могут быть возвращены в рабочее время обменного пункта в эквиваленте USDT на момент начала работы Сервиса.</span></li>

            <li><span>5.24. При работе с Заказами Пользователей, администрация Сервиса вправе:</span></li>

            <li><span>5.24.1. Прекратить общение с Пользователем, нарушающим этикет делового общения, задающим вопросы, не связанные с предоставлением Сервисом услуг или не предоставляющим Сервису необходимой для оказания услуг информации.</span></li>
            <li><span>5.24.2. При необходимости заблокировать проведение операции и Средства Пользователя до предоставления им полных и достаточных для идентификации его личности данных.</span></li>
            <li><span>5.24.3. Привлекать для исполнения своих обязательств сторонних исполнителей.</span></li>
            <li><span>5.24.4. Распоряжаться по собственному усмотрению любыми Средствами, поступившими на счет Сервиса без создания Заказа.</span></li>
            <li><span>5.24.5. Отправлять Пользователю на указанный им в Аккаунте e-mail информацию о состоянии процесса обмена, а также иную информацию, касающуюся деятельности Сервиса, в том числе рекламного характера. Отписаться от рекламных рассылок Пользователь может, нажав на соответствующую кнопку в полученном письме.</span></li>
            <li><span>5.24.6. Аннулировать выданный клиенту кошелек, что может привести к безвозвратной утере отправленных средств, в случае, если клиент не произвел перевод средств на данный кошелек и не прислал хэш (ID) транзакции в течение часа с момента выдачи кошелька. В случае если клиент описал и достоверно доказал причину задержки отправки средств, администрация вправе не аннулировать кошелек.</span></li>

            <li><h6>6. Стоимость услуг</h6></li>

            <li><span>6.1. Тарифы (курсы валют) на оказание услуг определяются Сервисом и публикуются на сайте Сервиса. Администрация Сервиса вправе менять тарифы (курсы валют) без дополнительного уведомления Пользователей.</span></li>

            <li><h6>7. Налогообложение операций Пользователя</h6></li>

            <li><span>7.1. Сервис не является налоговым агентом для Пользователя и не осуществляет исчисление налоговых платежей Пользователя, а также не обязан уведомлять Пользователя относительно его налоговых издержек. Пользователь обязуется самостоятельно выплачивать все налоги в соответствии с налоговым законодательством юрисдикции, где Пользователь является налоговым резидентом.</span></li>

            <li><span>7.2. Никакое взаимодействие Пользователя с Сервисом не может пониматься как установление между Пользователем и Сервисом агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо любых иных правоотношений, прямо не предусмотренных Правилами Сервиса.</span></li>

            <li><h6>8. KYC & AML</h6></li>

            <li><span>8.1. Сервис Coinschest.io осуществляет свою деятельность на основании законодательства государства, указанного в п. 1.3 настоящих Правил, а также ратифицированных международных соглашений:</span></li>

            <li><span>8.1.1. Guidance for a risk-based approach to virtual assets and virtual asset service providers (FATF).</span></li>
            <li><span>8.1.2. 5AMLD EU – Directive (EU) 2018/843 of the European Parliament and of the Council of 30 May 2018 amending Directive (EU) 2015/849 on the prevention of the use of the financial system for the purposes of money laundering or terrorist financing, and amending Directives 2009/138/EC and 2013/36/EU (Text with EEA relevance).</span></li>

            <li><span>8.2. В целях минимизации рисков легализации (отмывания) денежных средств и финансирования терроризма, Сервис оставляет за собой право отказаться от оказания услуг по обмену на любой стадии в случае предположения, что обмен любым образом связан с целями легализации (отмывания) денежных средств, финансированием терроризма или иной противоправной деятельности согласно законодательству государства размещения Сервиса, государства, резидентом которого является Пользователь, либо согласно международному законодательству.</span></li>

            <li><span>8.3. Защита персональных данных Пользователей обеспечивается Сервисом в соответствии с действующим законодательством государства размещения Сервиса и Конвенции о защите частных лиц в отношении автоматизированной обработки данных личного характера (СEД № 108).</span></li>

            <li><span>8.4. Способы обработки и защиты персональных данных Пользователей регулируются Положением об обработке и защите персональных данных, опубликованным на сайте Сервиса.</span></li>

            <li><span>8.5. В случае обнаружения фальсификации (компрометации) коммуникационных потоков или оказания любого негативного влияния на нормальную работу программного кода Сервиса, имеющей прямое или косвенное отношение к Заказу Пользователя, исполнение Заказа Сервисом приостанавливается, а по уже полученным средствам производится перерасчет параметров Заказа в соответствии с действующими условиями или, в случае несогласия Пользователя с перерасчетом, возврат Средств на реквизиты Пользователя.</span></li>

            <li><span>8.6. Любые споры, связанные с условиями настоящих Правил, стороны оферты обязуются урегулировать путем проведения переговоров. В случае недостижения соглашения, все споры подлежат разрешению в соответствии с законодательством государства нахождения Ответчика.</span></li>

            <li><span>8.7. Информация по обменным операциям сохраняется в базе данных Сервиса и является приоритетным источником, на который ориентируются стороны оферты, установленной настоящими Правилами, в спорных ситуациях.</span></li>

            <li><h6>9. Ответственность</h6></li>

            <li><span>9.1. Сервис не несет ответственности перед Пользователем за финансовые потери, вызванные противоправными действиями третьих лиц.</span></li>

            <li><span>9.2. Сервис не несет ответственности за любые отложенные или нереализованные Заказы, вызванные ошибкой Платежной системы или банка, указанных Пользователем в оформленном Заказе. Пользователь соглашается с тем, что в таком случае все претензии будут направлены в соответствующую Платежную систему или банк. Сервис по просьбе Пользователя оказывает содействие в предоставлении документов, подтверждающих использованием услуг Сервиса по обмену, при подаче Пользователем жалобы или требования администрации соответствующей Платежной системы или банку.</span></li>

            <li><span>9.3. Сервис не проверяет правомочность и законность владения Пользователем криптовалютой, электронными деньгами или фиатной валютой, участвующими в конкретной Операции. Сервис презюмирует добросовестность и легальность владения, пользования и распоряжения Пользователем банковских карт (счетов) и Средств на них, указываемых Пользователем при Платеже. Сервис не несет ответственности за владение, пользование и распоряжение Пользователем банковскими картами (счетами) и Средствами, ему не принадлежащими. Все риски и ответственность за владение, пользование и распоряжение банковскими картами (счетами) и Средствами на них, лежат на Пользователе.</span></li>

            <li><span>9.4. Пользователь обязан своевременно уведомлять администрацию Сервиса об изменениях в своем адресе электронной почты и номере телефона путем корректировки указанных данных в своем Аккаунте. В противном случае, Сервис не гарантирует получение Пользователем уведомлений о безопасности и не несет ответственности за негативные последствия для Пользователя в результате компрометации по независящим от Сервиса причинам его учетных данных для входа в Аккаунт.</span></li>

            <li><span>9.5. Пользователь обязан немедленно уведомить администрацию Сервиса о любом несанкционированном использовании учетной записи Пользователя или пароля, компрометации учетных данных, предполагаемом взломе Аккаунта или любом другом нарушении безопасности по электронной почте, указанную на данном сайте.</span></li>

            <li><span>9.6. Пользователь обязан не использовать средства для сокрытия своего действительного местоположения. Пользователь обязан сообщать администрации Сервиса по ее запросу свое точное и истинное местоположение. Если Сервис определит, что активность Пользователя является подозрительной или связана с любого рода противоправной деятельностью, Сервис может приостановить действие Аккаунта, заблокировать невыполненные либо отклонить последующие транзакции.</span></li>

            <li><span>9.7. Пользователь несет ответственность за достоверность и полноту информации и данных, которые он предоставил при регистрации на сайте Сервиса. В случае, если Пользователь ввел недостоверные либо некорректные личные данные или предоставил неверные данные для исполнения Заказа, Сервис не несёт ответственности за любые убытки Пользователя, возникшие в результате таких действий, независимо от их преднамеренности.</span></li>

            <li><span>9.8. Сервис не несет ответственности в случаях, когда Пользователь обращается к подложному сайту либо телеграмм-аккаунту, имитирующим настоящий адрес сайта Сервиса и его телеграмм-аккаунт («зеркалу»). Актуальные адрес в сети Интернет сайта Сервиса и его телеграмм-аккаунт указаны на сайте Сервиса “https://Coinschest.io/”.</span></li>

            <li><span>9.9. Использование Сервиса в целях осуществления любого рода противоправных действий запрещено.</span></li>

            <li><span>9.10. Все услуги Сервиса предоставляются без каких-либо явных или подразумеваемых гарантий, в частности, без подразумеваемых гарантий товарной пригодности и пригодности для определенной цели. Сервис не гарантирует, что все услуги Сервиса, а также сайт Coinschest.io будут доступны в 100% случаев для удовлетворения потребностей Пользователя. Сервис будет стремиться предоставить Пользователю свои услуги как можно скорее, но нет никаких гарантий, что доступ не будет прерван или что не будет никаких задержек, сбоев, ошибок, упущений или потери передаваемой информации, в том числе по вине третьих сторон.</span></li>
            <li><span>9.11. В случае принятия настоящих Правил, Пользователь подтверждает, что:</span></li>

            <li><span>9.11.1. им предоставлена полная и правдивая информация о себе, а также подлинные идентификационные данные.</span></li>
            <li><span>9.11.2. он не является участником операций или сделок по отмыванию денег, в соответствии с правилами KYC & AML.</span></li>
            <li><span>9.11.3. его доход не связан с осуществлением любой противоправной деятельности в соответствии с законодательством государства размещения Сервиса или государства пребывания Пользователя, в том числе в соответствии с правилами KYC & AML.</span></li>
            <li><span>9.11.4. он не состоит в любого рода «черных списках», «стоп-листах» или перечнях лиц, причастных к легализации (отмыванию) доходов и финансированию терроризма на территории страны его пребывания.</span></li>
            <li><span>9.11.5. он не привлекается правоохранительными либо фискальными органами государства его пребывания к юридической ответственности за легализацию (отмывание) доходов и финансирование терроризма, а равно за совершение мошенничества или любых других противоправных действий, связанных с хищением чужого имущества.</span></li>
            <li><span>9.11.6. торговля, а также любые действия, связанные с оборотом криптовалюты, не являются противоправными в соответствии с законодательством государства пребывания Пользователя.</span></li>

            <li><span>9.12. Пользователь обязуется не нарушать работу Сервиса путем вмешательства в его программную или аппаратную части, а также путем искажения параметров (команд), передаваемых Сервису.</span></li>

            <li><span>9.13. Если в результате действий Пользователя, вне зависимости от наличия у него умысла или по неосторожности, Сервису был нанесен ущерб, Пользователь обязуется компенсировать такой ущерб в полном размере.</span></li>

            <li><span>9.14. В случае, если Пользователю по Заказу поступила оплата больше установленной в Заказе, Пользователь обязуется произвести возврат средств на предоставленные Сервисом реквизиты. Комиссия за перевод в таком случае оплачивается обменным пунктом.</span></li>

            <li><span>9.15. Пользователь признает и соглашается с тем, что Сервис не выступает в качестве финансового консультанта, не предоставляет консультационные услуги по инвестициям, а любая информация, передаваемая Сервисом Пользователю не может рассматриваться как совет или руководство к действию.</span></li>

            <li><span>9.16. Пользователь осознает и принимает на себя все риски, связанные с оборотом криптовалюты.</span></li>

            <li><h6>10. Форс-мажор</h6></li>

            <li><span>10.1. Пользователь или Сервис не несут ответственности друг перед другом за невыполнение обязательств, связанных с оказанием Сервисом услуг по обмену, вызванное обстоятельствами, возникшими помимо воли и желания сторон, которые нельзя было предвидеть или избежать, включая объявленную или фактическую войну, гражданские волнения, эпидемии, землетрясения, наводнения, пожары и другие стихийные бедствия, действия органов власти и прочие непреодолимые обстоятельства и не могут заявлять ни о каких убытках или ущербе, возникших из-за таких обстоятельств.</span></li>

            <li><span>10.2. Сторона, которая не исполняет свое обязательство вследствие действия непреодолимой силы, должна известить другую Сторону о препятствии и его влиянии на исполнение обязательств без промедления, но не позднее 3 (трех) календарных дней с момента наступления указанных обстоятельств.</span></li>

            <li><span>10.3. Сторона, не известившая другую Сторону о невозможности исполнения своих обязательств по настоящему договору, теряет право ссылаться на такую невозможность.</span></li>

            <li><h6>11. Заключительные положения</h6></li>

            <li><span>11.1. Информация о Пользователе и о его операциях не хранится на сервере сайта Сервиса. По запросу Пользователя доступ к аккаунту может быть ограничен либо удален.</span></li>

            <li><span>11.2. Срок ответа Сервиса на запросы Пользователя составляет до пяти рабочих дней с момента получения соответствующего запроса Пользователя.</span></li>

            <li><span>11.3. Условия настоящих Правил согласовываются с Пользователем в электронной форме при регистрации. Согласие с Правилами, опубликованными в электронной форме, является действительным акцептом полного содержания настоящих Правил.</span></li>

            <li><span>11.4. Информация, размещенная на сайте Сервиса “https://coinschest.io/”, включая все графические изображения, текстовую информацию, коды программ и т.д. защищена национальным и международным законодательством об авторских правах и смежных с ними. Несанкционированное копирование материалов не допускается и влечет за собой полное возмещение причиненных Сервису убытков.</span></li>

            <li><h6>12. Контактные данные</h6></li>

            <li><span>12.1. Пользователь может связаться со специалистами и администрацией Сервиса в рабочее время следующими способами:</span></li>

            <li><span>12.1.1. в онлайн-чате на сайте – https://Coinschest.io.</span></li>
            <li><span>12.1.2. путем направления письма на адрес электронной почты, указанной на данном сайте.</span></li>
            <li><span>12.1.3. путем создания тикета в виджете на данном сайте.</span></li>
                </ul>
            </div>
        </div>
);
};

export default Rules;