import {createContext, useContext, useEffect, useRef, useState} from "react";
import axiosClient from "../http/axiosClient";


const stateContext = createContext({
    token: null,
    tokenExp: 0,
    setToken: () => {},
    setTokenExp: () => {},
});

export const AuthProvider = ({children}) => {
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN') || null);
    const [tokenExp, _setTokenExp] = useState(localStorage.getItem('ACCESS_TOKEN_EXP') || 0);
    const currentToken = useRef(null);

    const setToken = (token) => {
        _setToken(token);
        if (!token) {
            setTokenExp(0);
            localStorage.removeItem('ACCESS_TOKEN');
            localStorage.removeItem('user');
        } else {
            localStorage.setItem('ACCESS_TOKEN', token);
            localStorage.removeItem('REG_TOKEN');
        }
    }

    const setTokenExp = (tokenExp) => {
        _setTokenExp(tokenExp);
        if (tokenExp === 0 || tokenExp < 0) {
            localStorage.removeItem('ACCESS_TOKEN_EXP');
        } else {
            localStorage.setItem('ACCESS_TOKEN_EXP', tokenExp);
        }
    }

    const refreshToken = async () => {
        await axiosClient.get(process.env.REACT_APP_API_URL + '/refresh')
            .then(res => {
                if (res.status === 200) {
                    setToken(res.data.access_token);
                    setTokenExp(res.data.expires_in);
                } else {
                    setToken(null);
                }
            });
    }

    useEffect(() => {
        currentToken.current = token;
        if (currentToken.current) {
            let timeNow = Date.now();
            let timeout = tokenExp - timeNow - 60 * 1000;
            let refreshTimeout;
            console.log(timeout);
            if (token && timeout > 0) {
                refreshTimeout = setTimeout(async () =>{
                    if (!currentToken.current) {
                        return() => clearTimeout(refreshTimeout);
                    }
                    await refreshToken();
                }, timeout);
            } else {
                setToken(null);
            }
        }
    }, [token]);

    return (
        <stateContext.Provider value={{
            token,
            tokenExp,
            setToken,
            setTokenExp,
        }}>
            {children}
        </stateContext.Provider>
    )
}

export const useStateContext = () => useContext(stateContext);