import Logo from 'images/logo.svg';
import BonusIcon from 'images/bonus_icon.svg';
import FavouriteIcon from 'images/favourite_icon.svg';
import HistoryIcon from 'images/history_icon.svg';
import CardIcon from 'images/card_icon.svg';
import LoginIcon from 'images/login_icon.svg';
import ClientIcon from 'images/client.svg';
import ArrowIcon from 'images/select_arrow.svg';

import React, {useState} from 'react';
import {Link} from "react-router-dom";
import $ from 'jquery';
import {useDispatch} from "react-redux";
import {pageLogin} from "../redux/slices/signSlice";
import UserMenu from "./User/UserMenu";
import {useStateContext} from "./Helpers/auth/AuthProvider";


export default function Header() {
    const projectName = process.env.REACT_APP_PROJECT_NAME;
    const [openSandwich, setOpenSandwich] = useState(false);
    const [isClient, setIsClient] = useState(false);
    const dispatch = useDispatch();
    const {token} = useStateContext();

    React.useEffect(() => {
        if (token) {
            setIsClient(true);
        } else {
            setIsClient(false);
        }
    }, [token]);

    React.useEffect(() => {
        if (openSandwich) {
            $('body').addClass('scr_none');
            $('.footer').addClass('menu_open');
        } else {
            $('body').removeClass('scr_none');
            $('.footer').removeClass('menu_open');
        }
    }, [ openSandwich ]);

    const clickClientMob = () => {
        $('#client-mobmenu').toggleClass('none');
    }

    const clickClientMenu = () => {
        $('#client-menu').toggleClass('none');
    }

    return (
        <>
        <header className="header">

            <div className="container">

                <div className={`header-row ${ openSandwich ? 'open' : ''}`}>

                    <div className="header-logo">
                        <Link to="/" title={projectName}>
                            <div className="header-logo-img">
                                <img src={Logo} alt="Logo" />
                            </div>
                            <div className="header-logo-text">{projectName}</div>
                        </Link>
                    </div>

                    <div
                        className={`sandwich ${ openSandwich ? 'active' : ''}`}
                        onClick={() => setOpenSandwich(!openSandwich)}
                    ><span/></div>

                    <div className={`header-nav-mob ${ openSandwich ? '' : 'none'}`}>

                        <div className="h-n-m-row">

                            {/*<div className="nav-bonus">*/}
                            {/*    <Link to="/bonus/" title="Бонусы">*/}
                            {/*        <div className="h-n-m-row-img">*/}
                            {/*            <img src={BonusIcon} alt="Bonus icon" />*/}
                            {/*        </div>*/}
                            {/*        <div className="nav-bonus-text">Бонусы</div>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}

                            <div className="nav-favorite">
                                <Link to="/account/favourites/" title="Шаблоны обменов">
                                    <div className="h-n-m-row-img">
                                        <img src={FavouriteIcon} alt="Favorite icon" />
                                    </div>
                                    <div className="nav-favorite-text">Шаблоны</div>
                                </Link>
                            </div>

                        </div>

                        <div className="h-n-m-row">

                            <div className="nav-history">
                                <Link to="/account/history/" title="История операций">
                                    <div className="h-n-m-row-img">
                                        <img src={HistoryIcon} alt="History icon" />
                                    </div>
                                    <div className="nav-bonus-text">История</div>
                                </Link>
                            </div>

                            <div className="nav-bcards">
                                <Link to="/account/cards/" title="Банковские карты">
                                    <div className="h-n-m-row-img">
                                        <img src={CardIcon} alt="Card icon" />
                                    </div>
                                    <div className="nav-bonus-text">Карты</div>
                                </Link>
                            </div>

                        </div>

                        <div className="h-n-m-row">

                            {
                                isClient ? (
                                    <div
                                        id="nav-client-mob"
                                        className="nav-client"
                                        onClick={clickClientMob}
                                    >
                                        <div className="nav-client-div">
                                            <img className="nav-client-icon" src={ClientIcon} alt="Client" />
                                            <img src={ArrowIcon} alt="Menu" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="nav-login" onClick={() => dispatch(pageLogin())}>
                                        <a href="/sign/" title="Вход в личный кабинет">
                                            <div className="h-n-m-row-img">
                                                <img src={LoginIcon} alt="Login icon" />
                                            </div>
                                            <div className="nav-bonus-text">Войти</div>
                                        </a>
                                    </div>
                                )
                            }

                        </div>

                        <div id="client-mobmenu" className="client-menu none">
                            <UserMenu />
                        </div>

                    </div>

                    <div className="header-nav">

                        {/*<div className="nav-bonus">*/}
                        {/*    <Link to="/bonus/" title="Бонусы">*/}
                        {/*        <div className="nav-bonus-img">*/}
                        {/*            <img src={BonusIcon} alt="Bonus icon" />*/}
                        {/*        </div>*/}
                        {/*        <div className="nav-bonus-text">Бонусы</div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                        <div className="nav-favorite">
                            <Link to="/account/favourites/" title="Шаблоны обменов">
                                <div className="nav-favorite-img">
                                    <img src={FavouriteIcon} alt="Favorite icon" />
                                </div>
                                <div className="nav-favorite-text">Шаблоны</div>
                            </Link>
                        </div>

                        <div className="nav-history">
                            <Link to="/account/history/" title="История операций">
                                <div className="nav-bonus-img">
                                    <img src={HistoryIcon} alt="Bonus icon" />
                                </div>
                                <div className="nav-bonus-text">История</div>
                            </Link>
                        </div>

                        <div className="nav-bcards">
                            <Link to="/account/cards/" title="Банковские карты">
                                <div className="nav-bonus-img">
                                    <img src={CardIcon} alt="Bonus icon" />
                                </div>
                                <div className="nav-bonus-text">Карты</div>
                            </Link>
                        </div>

                        {
                            isClient ? (<>
                                <div id="nav-client" className="nav-client" onClick={clickClientMenu}>
                                    <div className="nav-client-div">
                                        <img className="nav-client-icon" src={ClientIcon} alt="Client" />
                                        <img src={ArrowIcon} alt="Menu" />
                                    </div>
                                </div>

                                <div id="client-menu" className="client-menu none" onClick={clickClientMenu}>
                                    <UserMenu />
                                </div>
                            </>) : (
                                <div className="nav-login" onClick={() => dispatch(pageLogin())}>
                                    <Link to="/sign/" title="Вход в личный кабинет">
                                        <div className="nav-bonus-img">
                                            <img src={LoginIcon} alt="Bonus icon"/>
                                        </div>
                                        <div className="nav-bonus-text">Войти</div>
                                    </Link>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </header>
    </>
    );

}




