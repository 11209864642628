import React from 'react';

const FAQ = () => {

    return (
        <section className="index_faq">
            <div className="i_f_block">
                <div className="i_f_bl_item">
                    <h5>Как долго выполняется заказ?</h5>
                    <p>До 5 мин</p>
                </div>
                <div className="i_f_bl_item">
                    <h5>Откуда рубли поступят на карту?</h5>
                    <p>С банковской карты. QIWI не поддерживаем</p>
                </div>
                <div className="i_f_bl_item">
                    <h5>Для чего нужна верификация карты?</h5>
                    <p>Чтобы ей не воспользовались мошенники</p>
                </div>
                <div className="i_f_bl_item">
                    <h5>Как долго верифицируется карта?</h5>
                    <p>≈ 15 мин</p>
                </div>
            </div>
        </section>
    );
};

export default FAQ;