import React from 'react';

const NotFound = () => {
    return (
        <div className="container">
            <h1>Страницы НЕТ !!!!!</h1>
        </div>
    );
};

export default NotFound;