import React from 'react';
import {Link} from "react-router-dom";


const UserMenu = () => {

    return (
        <>
            <div className="user_menu">
                <div className="u_m_row"><p>{ localStorage.getItem('user') }</p></div>
                <div className="u_m_separator"></div>
                <div className="u_m_row"><p><Link to="/account/favourites">Шаблоны</Link></p></div>
                <div className="u_m_row"><p><Link to="/account/history">История</Link></p></div>
                <div className="u_m_row"><p><Link to="/account/cards">Банк. карты</Link></p></div>
                <div className="u_m_row"><p><Link to="/account/partner">Партнерам</Link></p></div>
                <div className="u_m_separator"></div>
                <div className="u_m_row"><p className="red"><Link to="/account/logout">Выйти</Link></p></div>
            </div>

        </>
    )

}

export default UserMenu;