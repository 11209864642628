import React, {useRef} from "react";
import { useDispatch } from "react-redux";
import {pageConfirm} from "../../../redux/slices/signSlice";
import { Formik, Form , Field } from "formik";
import {signValidation} from "../../Helpers/validation/signValidation";
import axiosClient from "../../Helpers/http/axiosClient";
import {useStateContext} from "../../Helpers/auth/AuthProvider";


export const Register = () => {
    const dispatch = useDispatch();
    const [formErrorFromBackend, setFormErrorFromBackend] = React.useState('');

    async function sendLoginForm(values) {
        let fData = new FormData();
        fData.append('email', values.email);
        fData.append('password', values.password);
        fData.append('ref', localStorage.getItem('ref')?? null);
        fData.append('userIp', localStorage.getItem('userIp')?? null);

        const res = await axiosClient.post(
            process.env.REACT_APP_API_REGISTER_URL,
            fData,
        );
        if (res.status === 422) {
            setFormErrorFromBackend(res.data.message);
        }

        if (res.data && res.status === 201) {
            localStorage.setItem('user',res.data.user);
            localStorage.setItem('REG_TOKEN',res.data.access_token);
            dispatch(pageConfirm());
        }
    }

    React.useEffect(() => {
        if (formErrorFromBackend) {
            setTimeout(() => {
                setFormErrorFromBackend('');
            }, 5000);
        }
    });

    return (
        <>

            <div className="page_head">
                <div className="log_head_title">
                    <h2>Регистрация</h2>
                </div>
            </div>

            <div className="page_body">

                <Formik
                    validationSchema={signValidation}
                    initialValues={
                        {email: '', password: ''}
                    }
                    onSubmit={values => {sendLoginForm(values)}}
                >
                    { ({errors,touched}) => (

                            <Form>

                                <div className="log_body_signin">

                                    {
                                        formErrorFromBackend && !errors.email && (
                                            <span className="red">{formErrorFromBackend}</span>
                                        )
                                    }

                                    <Field
                                        className="log_body_sin_input"
                                        placeholder="E-mail"
                                        name="email"
                                    />
                                    {
                                        errors.email && touched.email && (
                                            <span className="red">{errors.email}</span>
                                        )
                                    }

                                    <Field
                                        type="password"
                                        className="log_body_sin_input"
                                        placeholder="Пароль"
                                        name="password"
                                        autoComplete="true"
                                    />
                                    {
                                        errors.password && touched.password && (
                                            <span className="red">{errors.password}</span>
                                        )
                                    }

                                    <button
                                        type="submit"
                                        className="yellow_btn log_body_sin_btn"
                                    ><span>Зарегистрироваться</span></button>
                                </div>

                            </Form>
                        )
                    }

                </Formik>

                <div className="accept_legal">
                    <p>Регистрация аккаунта {process.env.REACT_APP_PROJECT_NAME} означает ваше согласие с Политикой конфиденциальности и Условиями предоставления услуг.</p>
                </div>

            </div>

        </>
    )
}